<template>
  <div class="m-company-edit">
    <b-notification
      v-if="savingError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('companies.savingError') }}
    </b-notification>
    <b-loading :active.sync="loading"></b-loading>
    <h1 class="title is-1">{{ heading }}</h1>
    <div class="columns">
      <div class="column is-half">
        <div class="container ra-form-section">
          <b-field :label="$t('companies.name')" :type="nameType" :message="nameValidationMessage">
            <b-input
              v-model="name"
              :placeholder="$t('companies.namePlaceholder')"
              :maxlength="limits.name"
              @input="resetValidation"
            ></b-input>
          </b-field>

          <b-field :label="$t('companies.description')">
            <b-input
              type="textarea"
              v-model="description"
              :placeholder="$t('companies.descriptionPlaceholder')"
              :maxlength="limits.description"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="column is-half">
        <b-checkbox v-model="endOfWeekReminder">
          {{ $t('companies.endOfWeekReminder') }}
        </b-checkbox>

        <b-field :label="$t('companies.reminderContent')" v-if="endOfWeekReminder">
          <b-input
            type="textarea"
            v-model="reminderContent"
            :placeholder="$t('companies.reminderContentPlaceholder')"
            :maxlength="limits.reminderContent"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <b-button @click="cancel" type="is-secondary">{{ $t('generic.cancelButton') }}</b-button>
          </div>
          <div class="control">
            <b-button :disabled="loading" @click="dispatch" type="is-primary" :loading="saving">{{
              $t('generic.saveButton')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
import clientCompanies from '../../../api/companies';
import logger from '../../../utils/logger';

export default {
  name: 'CompanyEdit',
  props: {
    companyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      limits: {
        name: 50,
        description: 200,
        reminderContent: 600
      },
      loading: false,
      loadingError: false,
      saving: false,
      savingError: false,
      name: '',
      nameValidationMessage: '',
      nameType: '',
      description: '',
      endOfWeekReminder: false,
      reminderContent: ''
    };
  },
  computed: {
    rolesRegistry() {
      if (this.$store.state.system.registries) {
        return this.$store.state.system.registries.roles;
      }
      return [];
    },
    isEdit() {
      return !!this.companyId;
    },
    heading() {
      if (this.isEdit) {
        return this.$t('companies.editCompanyHeading');
      } else {
        return this.$t('companies.createCompanyHeading');
      }
    }
  },
  methods: {
    createDataPayload() {
      const data = {
        name: this.name,
        description: this.description,
        endOfWeekReminder: this.endOfWeekReminder,
        reminderContent: this.reminderContent
      };
      if (this.isEdit) {
        return { id: this.companyId, data };
      } else {
        return data;
      }
    },
    loadCompany() {
      if (!this.companyId) {
        return false;
      }
      this.loading = true;
      this.loadingError = false;
      clientCompanies
        .get(this.companyId)
        .then(company => {
          this.name = company.name;
          this.description = company.description;
          this.endOfWeekReminder = company.endOfWeekReminder;
          this.reminderContent = company.reminderContent;
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isFormValid() {
      if (this.name === '') {
        (this.nameType = 'is-danger'), (this.nameValidationMessage = this.$t('companies.emptyNameError'));
        return false;
      }
      return true;
    },
    resetValidation() {
      this.nameType = '';
      this.nameValidationMessage = '';
    },
    dispatch() {
      if (!this.isFormValid()) {
        return false;
      }
      this.saving = true;
      this.savingError = false;
      let payload = this.createDataPayload();
      this.$store
        .dispatch(namespaces.COMPANIES + (this.isEdit ? actions.EDIT_COMPANY : actions.ADD_COMPANY), payload)
        .then(() => {
          this.$router.replace({ name: 'companies-list' });
        })
        .catch(error => {
          this.savingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.name = '';
      this.description = '';
      this.endOfWeekReminder = false;
      this.reminderContent = '';
      this.$router.go(-1);
    }
  },
  mounted() {
    this.loadCompany();
  },
  watch: {
    companyId() {
      this.loadCompany();
    }
  }
};
</script>
